import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Simple drawing and Animation inside canvas with React",
  "date": "2020-08-30T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sometimes hard to find some basic steps to make some features, for me some time ago it was integration react with canvas and adding animation. Here I'll show basic steps that should help to jump into this stack and made a simple interaction.`}</p>
    <h3>{`Let's start with some code.`}</h3>
    <p>{`Define our first component and draw first rectangle figure.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function CanvasComponent() {
  const canvasRef = useRef(null);

  useEffect(() => {
    let canvas = canvasRef.current;
    let ctx = canvas.getContext("2d");

    ctx.fillStyle = "tomato";
    ctx.fillRect(10, 10, 50, 50);
  });

  return <canvas ref={canvasRef} width="300" height="200" />;
}
`}</code></pre>
    <p>{`Initialize link to our dom element with help of `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-reference.html#useref"
      }}>{`useRef`}</a>{`, and now we have a connection with our canvasт, and then through `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` we are getting inner canvas context engine (`}<inlineCode parentName="p">{`ctx`}</inlineCode>{`) and draw a rectangle.`}</p>
    <h3>{`We have our rectangle, time to add animation`}</h3>
    <p>{`To initialize animation on the canvas we need to write our custom function that will be responsible for animation with the help of `}<inlineCode parentName="p">{`requestAnimationFrame`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function widthAnimation(start, ctx) {
  return function step(timestamp) {
    if (!start) start = timestamp;
    let progress = timestamp - start;
    let limit = 280;
    let xValue = Math.min(progress / 10, limit);

    ctx.fillRect(xValue, 10, 50, 50);

    if (xValue < limit) {
      window.requestAnimationFrame(step);
    }
  };
}
`}</code></pre>
    <p>{`Passing 2 arguments inside to our function, first one it's initial value for step, and the second one its context of our canvas that we are saving to the scope with the first initialization, this function return another recursive function `}<inlineCode parentName="p">{`step`}</inlineCode>{` that is will be repeated till our value do not get threshold in our case it's 200 ( `}<inlineCode parentName="p">{`limit = 280`}</inlineCode>{` ).`}</p>
    <p>{`And now we call our animation function `}<strong parentName="p">{`inside useEffect, inside`}</strong>{` `}<inlineCode parentName="p">{`animationFunc`}</inlineCode>{` `}<strong parentName="p">{`we are passing our parameters, and then call animation`}</strong>{` `}<inlineCode parentName="p">{`window.requestAnimationFrame(animation`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`useEffect(() => {
  const canvas = canvasRef.current;
  const ctx = canvas.getContext("2d");

  ctx.fillStyle = "tomato";
  ctx.fillRect(10, 10, 50, 50);
  let animationFunc = widthAnimation(null, ctx);
  window.requestAnimationFrame(animationFunc);
});
`}</code></pre>
    {
      /* // gif with animation */
    }
    <p>{`Something goes wrong, we wanted to move rectangle from one corner to another, but we just increase his width`}</p>
    <p>{`This is specific of canvas drawing, after each drawing we have previous state of all our drawing on the canvas, it means all this path from 0 to 280 we have multiple amounts of rectangels. To fix this we need clear our canvas context before each drawFunction like this `}<inlineCode parentName="p">{`ctx.clearRect(0,0,300,200`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`return function step(timestamp) {
  //...
  ctx.clearRect(0, 0, 300, 200)
  ctx.fillRect(xValue, 10, 50, 50)
  //...
}
`}</code></pre>
    <p>{`/ gif with correct behavior`}</p>
    <h3>{`Controls for Animation`}</h3>
    <p>{`Here is how we can do our animation contollable`}</p>
    <p>{`Creating `}<inlineCode parentName="p">{`useState`}</inlineCode>{` for toggle animation`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const [isActive, toggleAnimation] = useState(false);
`}</code></pre>
    <p>{`Adding controls to Run and Reset state`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<div>
  <button
    style={styles.button}
    type="button"
    onClick={() => toggleAnimation(true)}
  >
    Run
  </button>
  <button
    style={styles.button}
    type="button"
    onClick={() => toggleAnimation(false)}
  >
    Reset
  </button>
</div>
`}</code></pre>
    <p>{`And now we need add to our `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` condition at the onset of which we are running animattion`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`useEffect(() => {
  const canvas = canvasRef.current;
  const ctx = canvas.getContext("2d");
  let animationFunc = widthAnimation(null, ctx);
  let animation = null;
  ctx.clearRect(0, 0, 300, 200);

  ctx.fillStyle = "tomato";
  ctx.fillRect(10, 10, 50, 50);

  if (isActive) {
    animation = window.requestAnimationFrame(animationFunc);
  }

  return () => {
    window.cancelAnimationFrame(animation);
  };
});
`}</code></pre>
    <p>{`One important part, we should clear / reset our hook with the return function , it's should be done to prevent performance issue and possible bug`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`useEffect(() => {
...
  return () => {
    window.cancelAnimationFrame(animation)
  }
})
`}</code></pre>
    <h3>{`As a conclusion`}</h3>
    <p>{`Here we are met how is working simple drawing inside canvas with React, and add basic animation interaction `}<inlineCode parentName="p">{`requestAnimationFrame`}</inlineCode>{` with `}<inlineCode parentName="p">{`useEffect.`}</inlineCode></p>
    <p><a parentName="p" {...{
        "href": "https://developer.mozilla.org/docs/Web/API/Canvas_API/Tutorial/%D0%9E%D1%81%D0%BD%D0%BE%D0%B2%D1%8B_%D0%B0%D0%BD%D0%B8%D0%BC%D0%B0%D1%86%D0%B8%D0%B8"
      }}>{`Simple Canvas Animation MDN`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-effect.html#effects-with-cleanup"
      }}>{`Cleanup useEffect`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      